<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page admin-panel-main">
        <div class="admin-panel-inner">
            <Layout></Layout>
            <div class="admin-panel-rgt-inner ">
                <Topbar></Topbar>
                <div class="admin-right-cntn">
                  <div class="dashboard-user-manual">
                    <div class="user-manual-item total-user">
                      <div class="user-maual-icon">
                        <i class="fa-solid fa-users"></i>
                      </div>
                      <div class="user-maual-data">
                          <h3>{{this.datas.usersCount}}</h3>
                          <p>Total User</p>
                      </div>
                    </div>
                    <!-- <div class="user-manual-item capital-megin">
                      <div class="user-maual-icon">
                        <i class="fa-solid fa-users"></i>
                      </div>
                      <div class="user-maual-data">
                          <h3>$0</h3>
                          <p>Capital Margin</p>
                      </div>
                    </div> -->

                  </div>
                </div>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  import Layout from "@/components/Layout.vue"; 
  import Topbar from "@/components/Topbar.vue";
  import { API } from "../api/api";
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Dashboard",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      Layout,
      Topbar
  },
  data() {
    return {
      datas: Object
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
          API.get(`auth/dashboard`, head)
        .then(response => {
          console.log(response, "All pakage details")
          this.datas = response.data.data; 
        })
        .catch(error => {
          console.error('Error fetching pakages', error);
        });
    }
  }
  }
  </script>
  
  <style>
  /* Your dashboard component styles here */
  </style>
  