<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page admin-panel-main">
        <div class="admin-panel-inner">
            <Layout></Layout>
            <div class="admin-panel-rgt-inner ">
                <Topbar></Topbar>
                <div class="admin-right-cntn">
                      <div class="dashboard-table-responsive table-responsive">
                        <h3>Report Management</h3>
                          <table class="table table-bordered bg-light w-100">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Create Date</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">
                                <tr v-for="(report, index) in reportList" :key="index">
                                  <td>{{ report.user.firstName }}</td>
                                  <td>{{ formatDateTime(report.createdAt) }}</td>
                                  <td><a :href="report.invoice_pdf" target="_blank"> Download </a></td>
                                </tr>
                              </tbody>
                            </table>
                         
  
                            <h6 v-if="reportList.length === 0" class="text-center mt-2">No data found.</h6>
                            <nav aria-label="Page navigation example" v-if="reportList.length !== 0">
                              <ul class="pagination">
                                <li class="page-item">
                                  <a class="page-link" href="javascript:void(0);" aria-label="Previous"
                                  @click="prevPage" :class="{ 'disabled': currentPage === 1 }">
                                    <span aria-hidden="true">&laquo;</span>
                                  </a>
                                </li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0);">{{ currentPage  }}</a></li>
                                <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                <li class="page-item">
                                  <a class="page-link" href="javascript:void(0);" aria-label="Next" @click="nextPage" :class="{ 'disabled': isNextDisabled }">
                                    <span aria-hidden="true">&raquo;</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                      </div>
                  </div>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  import Layout from "@/components/Layout.vue"; 
  import Topbar from "@/components/Topbar.vue";
  import { API } from "../api/api";
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Reportmanagement",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      Layout,
      Topbar
  },
  data() {
      return {
        reportList: [],
        currentPage: 1,
        limit: 10, // Adjust the limit as needed
        totalReportCount:null,
      };
    },
    created() {
      this.fetchReports();
    },
    methods: {
        fetchReports() {
        var head = {
              headers: {
                  "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
              }
            };
        API.get(`invoices/list?page=${this.currentPage}&limit=${this.limit}`, head)
          .then(response => {
            console.log(response, "All Report details")
            this.reportList = response.data.data.rows; // Assuming the response contains an array of users
            this.totalReportCount = response.data.data.count;
          })
          .catch(error => {
            console.error('Error fetching reports', error);
          });
      },
      nextPage() {
        this.currentPage++;
        this.fetchReports();
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.fetchReports();
        }
      },
      formatDateTime(dateTime) {
        const date = new Date(dateTime);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        // const hours = `0${date.getHours()}`.slice(-2);
        // const minutes = `0${date.getMinutes()}`.slice(-2);
        
        // return `${year}-${month}-${day} ${hours}:${minutes}`;
        return `${year}-${month}-${day}`;
      }
    },
    computed: {
      isNextDisabled() {
        return this.currentPage * this.limit >= this.totalReportCount;
      }
    },
  }
  </script>
  
  <style>
  /* Your dashboard component styles here */
  </style>
  