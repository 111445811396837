<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="contact_form">
        <div class="container">
            <div class="cntact_logo">
                <img src="assets/images/aanantya-logo.png" alt="">
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-10 col-md-12 col-sm-12">
                    <div class="contact_main">
                        <div class="contact_form_left">
                            <img src="assets/images/login-bg.png" alt="contact us">
                        </div>
                        <div class="contact_form_rgt">
                            <h2>Samskrit Chatbot <br><strong>Sign in to start your session</strong></h2>
                            <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>

                            <form @submit.prevent="submitForm">
                                <input type="hidden" name="_token" value="lxyNh3suaXP2yoeKMCT1kZDpmSe4GbwsqgxWJNnI">
                                <div class="form-group">
                                    <label for="youremail">Your Email</label>
                                    <input type="email" class="form-control" id="email" placeholder="Email" name="email" v-model.trim="formData.email" />
                                    <span class="formError" v-if="formSubmitted && !formData.email">Email is required</span>
                                    <span class="formError" v-else-if="formSubmitted && !validateEmail(formData.email)">Please enter a valid email</span>
                                </div>

                                <div class="form-group">
                                    <label for="yourpassword">Password</label>
                                    <input type="password" class="form-control" name="password" id="password"
                                        placeholder="Enter Password" v-model.trim="formData.password" />
                                    <span class="formError" v-if="formSubmitted && !formData.password">Password is required</span>
                                </div>

                                <div class="form-group text-center mb-0">
                                    <button type="submit" class="btn">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  </template>
  
  <script>
  import { API } from "../api/api";

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Login',
	data() {
        return {
            formData: {
                email: "",
                password: ""
            },
            formSubmitted: false,
            errorMsg: ""
        };

    },
	methods: {
		submitForm() {
      this.formSubmitted = true; // Set form submitted status
      if (this.validateForm()) {
        console.log('Form submitted:', this.formData);
        const loginData = {
          user_email: this.formData.email,
          user_password: this.formData.password
        }
         // Perform form submission (e.g., API call)
        API.post("auth/signin",  loginData)
      .then(response => {
                console.log(response, "After Login");
				localStorage.setItem("adminInfo", JSON.stringify(response.data.data));
                localStorage.setItem("adminToken",JSON.stringify(response.data.data.token));
                this.$router.push('/dashboard');
            }).catch(error => {
                  console.error("fail", error);
				this.errorMsg = error.response.data.message;
                  });
      } else {
        console.log('Form has errors');
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validateForm() {
      return this.formData.email &&
             this.validateEmail(this.formData.email) &&
             this.formData.password
    },
	}
  };
  </script>
  
  <style>
  /* Your login component styles here */
  </style>
  