<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="main-page admin-panel-main">
      <div class="admin-panel-inner">
          <Layout></Layout>
          <div class="admin-panel-rgt-inner ">
              <Topbar></Topbar>
              <div class="admin-right-cntn">
                    <div class="dashboard-table-responsive table-responsive">
                      <h3>Permanent Referral Management</h3>
                     
                      <div v-if="successMsg" class="alert">{{ successMsg }}</div>
                      <div class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</div>
                        <table class="table table-bordered bg-light w-100">
                            <thead>
                              <tr>
                                <th scope="col">Code</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr v-for="(code, index) in codeList" :key="index">
                                <td>{{ code.referral.referralCode }}</td>
                                <td>{{ code.user.firstName }} {{ code.user.middleName }} {{ code.user.lastName }}</td>
                                <td>{{ code.user.email }}</td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" :disabled="!code.isActive" @click="toggleCheckbox(index)" :checked="!code.isActive">
                                        <div class="slider round"></div>
                                    </label>
                                </td>
                                
                              </tr>
                            </tbody>
                          </table>
                        

                          <h6 v-if="codeList.length === 0" class="text-center mt-2">No data found.</h6>
                          <nav aria-label="Page navigation example" v-if="codeList.length !== 0">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Previous"
                                @click="prevPage" :class="{ 'disabled': currentPage === 1 }">
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="javascript:void(0);">{{ currentPage  }}</a></li>
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Next" @click="nextPage" :class="{ 'disabled': isNextDisabled }">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                    </div>
                </div>
          </div>
      </div>
  </section>
</template>

<script>
import Layout from "@/components/Layout.vue"; 
import Topbar from "@/components/Topbar.vue";
import { API } from "../api/api";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PermanentReferral",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout,
    Topbar
},
data() {
    return {
      codeList: [],
      currentPage: 1,
      limit: 10, // Adjust the limit as needed
      totalReferralCount:null,
      successMsg: '',
      errorMsg: '',
      checkbox: false,
    };
  },
  created() {
    this.fetchCodes();
  },
  methods: {
    fetchCodes() {
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`permanentReferralUsers/findAll?page=${this.currentPage}&limit=${this.limit}`, head)
        .then(response => {
          console.log(response, "All Code details")
          this.codeList = response.data.data.permanentReferralData; // Assuming the response contains an array of users
          this.totalReferralCount = response.data.data.count;
        })
        .catch(error => {
          console.error('Error fetching users', error);
        });
    },
    nextPage() {
      this.currentPage++;
      this.fetchCodes();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchCodes();
      }
    },
    toggleCheckbox(index) {
        this.codeList[index].isActive = !this.codeList[index].isActive
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
          const sendData = {
            id: this.codeList[index].id
          }
          API.patch("permanentReferralUsers/deactivate",  sendData, head)
     .then(response => {
               console.log(response, "After Status Change");
               this.successMsg = response.data.message;
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 });
      
    //   this.$emit('setCheckboxVal', this.checkbox)
    },
    
  },
  computed: {
    isNextDisabled() {
      return this.currentPage * this.limit >= this.totalReferralCount;
    }
  },
}
</script>

<style scoped>
</style>
