import axios from 'axios';

const apiBaseUrl = 'https://api.aanantya.co/';   /// Sanskrit For Live 
// const apiBaseUrl = 'https://devapi.aanantya.co/';   /// Sanskrit For Dev
// const apiBaseUrl = 'https://tamilapi.aanantya.co/';   /// Tamil For Live
// const apiBaseUrl = 'https://hindiapi.aanantya.co/';   /// Hindi For Live
// const apiBaseUrl = 'https://bengaliapi.aanantya.co/';  /// For Bengali Live

export const API = axios.create({
    baseURL: apiBaseUrl,
    // timeout: 10000, // Set your default timeout here (10 seconds in this example)
    headers: {
       
     
       
    }
});


API.interceptors.request.use(function (config) {
    const token = localStorage.getItem('Token');
    if ( token != null ) {
        config.headers.accessToken = `${token}`;
    }
    
    return config;
}, function(err) {
    return Promise.reject(err);
});