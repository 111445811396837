<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page admin-panel-main">
          <div class="admin-panel-inner">
              <Layout></Layout>
              <div class="admin-panel-rgt-inner ">
                  <Topbar></Topbar>
                  <div class="admin-right-cntn">
                      <h3>Landing Page</h3>
                      <!-- <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p> -->
                      <div v-if="errorMsg" class="alert-error">{{ errorMsg }}</div>
                      <!-- <p class="successMsg" v-if="successMsg !== ''">{{ successMsg }}</p> -->
                      <div v-if="successMsg" class="alert">{{ successMsg }}</div>
                      <div class="form-container">
                          <form @submit.prevent="submitForm">

                            <!-- 1st Section -->
                            <label><strong><u>First Section:</u></strong></label>
                            <div class="mb-3">
                                <label :for="`textInput`" class="form-label"><strong class="templateHead">Top Content:</strong></label>
                                <ckeditor :id="`textInput`" :editor="editor" v-model="formData.firstSection.textContent" class="custom-ckeditor"></ckeditor>
                            </div>

                            <!-- <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label"><strong class="templateHead">Right Image </strong></label>
                                <input type="file" class="form-control" id="profilepic" @change="handleFirstSectionFileUpload" accept="image/*" />
                                <div class="previewImage" v-if="formData.firstSection.imageSource != null">
                                  <img :src="formData.firstSection.imageSource" alt="Uploaded Image"/>
                                </div>
                            </div> -->

                            <!-- For Slider  -->

                            <!-- Button to Add Section -->
                            <div class="add-section">
                              <button type="button" @click="addSection('first')" class="btn btn-success">Add Slider Section</button>
                            </div> <br>

                            <div v-for="(slider, index) in formData.firstSection.sliderArray" :key="index">
                                <div class="mb-3">
                                  <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Video Iframe {{ index + 1 }}</strong></label>
                                  <textarea class="form-control" v-model="slider.videoSource" style="height: 100px;"></textarea>
                                </div>

                                <div class="mb-3">
                                  <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Video Text Content {{ index + 1 }}</strong></label>
                                  <ckeditor :id="`textInput`" :editor="editor" v-model="formData.firstSection.sliderArray[index].videoTextContent" class="custom-ckeditor"></ckeditor>
                                </div>

                                <!-- Button to Remove Section -->
                                <div class="add-section">
                                <button type="button" @click="removeSection(index,'first', 'slider')" class="btn btn-danger">Remove</button>
                                </div> <br>
                            </div>

                              <!-- End Slider  -->

                               <!-- Button to Add Section -->
                            <div class="add-section" v-if="formData.firstSection.gridArray.length < 3">
                              <button type="button" @click="addSection('first', 'grid')" class="btn btn-success">Add Grid Section</button>
                            </div>

                              <div v-for="(grid, index) in formData.firstSection.gridArray" :key="index">
                                <div class="mb-3">
                                    <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Grid Content {{ index + 1 }} </strong></label>
                                    <ckeditor :id="`textInput`" :editor="editor" v-model="formData.firstSection.gridArray[index]" class="custom-ckeditor"></ckeditor>
                                </div>
                                <!-- Button to Remove Section -->
                                <div class="add-section">
                                <button type="button" @click="removeSection(index,'first')" class="btn btn-danger">Remove</button>
                                </div>
                             </div>

                              <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Email Text Content </strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.firstSection.emailTextContent">
                              </div>

                              

                              <!-- 2nd section -->
                              <label><strong><u>Second Section:</u></strong></label>
                              <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Title </strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.secondSection.title">
                              </div>

                              <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Sub Title </strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.secondSection.subTitle">
                              </div>

                               <!-- Button to Add Section -->
                            <div class="add-section">
                              <button type="button" @click="addSection('second')" class="btn btn-success">Add Grid Section</button>
                            </div>

                              <div v-for="(grid, index) in formData.secondSection.gridArray" :key="index">
                                <!-- <div class="mb-3">
                                    <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Title {{ index + 1 }}</strong></label>
                                    <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="grid.title">
                               </div> -->
                                <!-- File Upload -->
                                <div class="mb-3">
                                <label :for="`fileInput-${index}`" class="form-label"><strong class="templateHead">Upload File {{ index+1 }}:</strong></label>
                                <input :id="`fileInput-${index}`" type="file" class="form-control" @change="handleFileUploadLoop($event, index, 'second')" />
                                <div class="previewImage" v-if="formData.secondSection.gridArray[index].imageSource != null">
                                  <img :src="formData.secondSection.gridArray[index].imageSource" alt="Uploaded Image"/>
                                </div>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Grid Content {{ index + 1 }} </strong></label>
                                    <ckeditor :id="`textInput`" :editor="editor" v-model="formData.secondSection.gridArray[index].textContent" class="custom-ckeditor"></ckeditor>
                                </div>
                                <!-- Button to Remove Section -->
                                <div class="add-section">
                                <button type="button" @click="removeSection(index,'second')" class="btn btn-danger">Remove</button>
                                </div>
                             </div>
                              

                               <!-- 4th section -->
                               <label><strong><u>Fourth Section:</u></strong></label>
                               <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Title </strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.fourthSection.title">
                              </div>

                              <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Sub Title </strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.fourthSection.subTitle">
                              </div>

                               <!-- Button to Add Section -->
                            <div class="add-section">
                              <button type="button" @click="addSection('fourth')" class="btn btn-success">Add Grid Section</button>
                            </div>

                              <div v-for="(grid, index) in formData.fourthSection.gridArray" :key="index">
                                <div class="mb-3" v-if="index % 2 === 0">
                                    <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Grid Content </strong></label>
                                    <ckeditor :id="`textInput`" :editor="editor" v-model="formData.fourthSection.gridArray[index].textContentOrImage" class="custom-ckeditor"></ckeditor>
                                </div>
                                <!-- File Upload -->
                                <div class="mb-3" v-else>
                                <label :for="`fileInput-${index}`" class="form-label"><strong class="templateHead">Upload File</strong></label>
                                <input :id="`fileInput-${index}`" type="file" class="form-control" @change="handleFileUploadLoop($event, index, 'fourth')" />
                                <div class="previewImage" v-if="formData.fourthSection.gridArray[index].textContentOrImage !== ''">
                                  <img :src="formData.fourthSection.gridArray[index].textContentOrImage" alt="Uploaded Image"/>
                                </div>
                                </div>
                                <!-- Button to Remove Section -->
                                <!-- <div class="add-section">
                                <button type="button" @click="removeSection(index,'fourth')" class="btn btn-danger">Remove</button>
                                </div> -->
                             </div>

                             <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Bottom Content </strong></label>
                                <textarea class="form-control" v-model="formData.fourthSection.bottomContent" style="height: 118px;"></textarea>
                              </div>


                              <!-- 5th Section -->
                            <label><strong><u>Fifth Section:</u></strong></label>
                            <div class="mb-3">
                                <label :for="`textInput`" class="form-label"><strong class="templateHead">Blog Heading:</strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.fifthSection.blogsHeading">
                            </div>

                            <div class="mb-3">
                                <label :for="`textInput`" class="form-label"><strong class="templateHead">Blog Sub Heading:</strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.fifthSection.blogsSubHeading">
                            </div>

                            <!-- <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label"><strong class="templateHead">Right Image </strong></label>
                                <input type="file" class="form-control" id="profilepic" @change="handleFifthSectionFileUpload" accept="image/*" />
                                <div class="previewImage" v-if="formData.fifthSection.imageSource != null">
                                  <img :src="formData.fifthSection.imageSource" alt="Uploaded Image"/>
                                </div>
                            </div> -->

                                 <!-- 6th section -->
                                 <label><strong><u>Sixth Section:</u></strong></label>
                              <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Title </strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.sixthSection.title">
                              </div>

                              <div class="mb-3">
                                <label for="exampleInputTitle" class="form-label"><strong class="templateHead">Sub Title </strong></label>
                                <input type="text" class="form-control" id="exampleInputTitle" aria-describedby="emailHelp" v-model.trim="formData.sixthSection.subTitle">
                              </div>

                               <!-- Button to Add Section -->
                            <div class="add-section">
                              <button type="button" @click="addSection('sixth')" class="btn btn-success">Add Grid Section</button>
                            </div>


                            <div v-for="(section, index) in formData.sixthSection.gridArray" :key="index">
                                <!-- File Upload -->
                                <div class="mb-3">
                                <label :for="`fileInput-${index}`" class="form-label"><strong class="templateHead">Upload File {{ index+1 }}:</strong></label>
                                <input :id="`fileInput-${index}`" type="file" class="form-control" @change="handleFileUploadLoop($event, index, 'sixth')" />
                                <div class="previewImage" v-if="formData.sixthSection.gridArray[index].imageSource != null">
                                  <img :src="formData.sixthSection.gridArray[index].imageSource" alt="Uploaded Image"/>
                                </div>
                                </div>
                                <!-- Textarea -->
                                <div class="mb-3">
                                <label :for="`textInput-${index}`" class="form-label"><strong class="templateHead">Content {{ index+1 }}</strong></label>
                                <ckeditor :id="`textInput-${index}`" :editor="editor" v-model="formData.sixthSection.gridArray[index].textContent" class="custom-ckeditor"></ckeditor>
                                </div>
                                <!-- Button to Remove Section -->
                                <div class="add-section">
                                <button type="button" @click="removeSection(index,'sixth')" class="btn btn-danger">Remove</button>
                                </div>
                              </div>

       
                              <button type="submit" class="btn btn-primary">Submit</button>
                            </form>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </template>
  
  <script>
  import Layout from "@/components/Layout.vue"; 
  import Topbar from "@/components/Topbar.vue";
  import { API } from "../api/api";
  // Import CKEditor component and package
  import CKEditor from '@ckeditor/ckeditor5-vue';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "HomePage",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      Layout,
      Topbar,
      ckeditor: CKEditor.component
  },
  data() {
      return {
          editor: ClassicEditor,
          errorMsg: '',
          successMsg: '',
          formData: {
            firstSection: {
              textContent: '',
              // videoSource: null, 
              // videoTextContent: '',
              sliderArray: [], 
              gridArray: [], 
              emailTextContent: ''
            },
            secondSection: { 
              title: '', 
              subTitle: '', 
              gridArray: []
            },
            
            fourthSection: { 
              title: '', 
              subTitle: '', 
              gridArray: [],
              bottomContent:''
            },
            fifthSection: {
              blogsHeading: '', 
              blogsSubHeading: ''
            },
            sixthSection: { 
              title: '', 
              subTitle: '', 
              gridArray: []
            }
            },
        formSubmitted: false, // Track form submission status
      };
    },
    created() {
      this.fetchLandingContent();
    },
    methods: {
      async fetchLandingContent() {
  
        var head = {
              headers: {
                  "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
              }
            };
        API.get(`generic/landingpage`, head)
          .then(response => {
            console.log(response, "Landing Page details")
             this.formData = response.data.data;
          })
          .catch(error => {
              console.error('Error fetching content:', error);
          });
      },
      
      submitForm() {
        console.log('Form values:', this.formData);
         this.formSubmitted = true; // Set form submitted status
   
        var head = {
              headers: {
                  "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
              }
            };
            // Perform form submission (e.g., API call)
         API.patch("generic/landingpage",  this.formData, head)
       .then(response => {
                 console.log(response, "After Template update");
                 this.successMsg = response.data.message;
                 this.errorMsg = '';
                 setTimeout(() => {
                    this.successMsg = '';
                }, 3000); // Hide alert after 3 seconds
             }).catch(error => {
                   console.error("fail", error);
                   this.successMsg = '';
                   this.errorMsg = error.response.data.message;
                   });
       
     },
     validateForm() {
      return this.formData.topTitle &&
             this.formData.topSubTitle &&
             this.formData.topContent &&
             this.formData.secondContentLeft
    },
    handleFirstSectionFileUpload(event){
      const file = event.target.files[0]; // Get the uploaded file
      if (file) {
         this.previewImage(file);  // Preview the selected image
        // You can also upload the file to a server using Api or fetch
         this.uploadFileToServer(file);
      }
    },

    handleFifthSectionFileUpload(event){
        const file = event.target.files[0]; // Get the uploaded file
      if (file) {
         this.previewFifthImage(file);  // Preview the selected image
        // You can also upload the file to a server using Api or fetch
         this.uploadFifthFileToServer(file);
      }
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
          this.formData.firstSection.imageSource = reader.result;
         // Set the uploaded image as the source
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    },
    previewFifthImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
          this.formData.fifthSection.imageSource = reader.result;
         // Set the uploaded image as the source
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    },
    uploadFileToServer(file) {
      const formData = new FormData();
      formData.append('file', file);
      API.post('auth/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log('File uploaded!', response);
        this.formData.firstSection.imageSource = response.data.data.location;
      }).catch(error => {
        console.error('Error uploading file', error);
      });
    },
    uploadFifthFileToServer(file){
        const formData = new FormData();
      formData.append('file', file);
      API.post('auth/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log('File uploaded!', response);
        this.formData.fifthSection.imageSource = response.data.data.location;
      }).catch(error => {
        console.error('Error uploading file', error);
      });
    },
    addSection(type, section = null) {
      // Add a new section
      console.log(type, "////", section)
      if(type === 'first'){
        section === 'grid' ? this.formData.firstSection.gridArray.push('') :  this.formData.firstSection.sliderArray.push({videoSource: null,videoTextContent: ''}) 
      }
      if(type === 'second'){
        // this.formData.secondSection.gridArray.push({title: '', textContent: ''});
        this.formData.secondSection.gridArray.push({imageSource: null, textContent: ''});
      }
      if(type === 'fourth'){
          this.formData.fourthSection.gridArray.push({textContentOrImage : ''});
      }
      if(type === 'sixth'){
        this.formData.sixthSection.gridArray.push({imageSource: null, textContent: ''});
      }
      
    },
    removeSection(index, type, section = null) {
      if(type === 'first'){
        section === 'slider' ? this.formData.firstSection.sliderArray.splice(index, 1) : this.formData.firstSection.gridArray.splice(index, 1);
      }
      if(type === 'second'){
        this.formData.secondSection.gridArray.splice(index, 1);
      }
      if(type === 'fourth'){
      this.formData.fourthSection.gridArray.splice(index, 1);
      }
      if(type === 'sixth'){
      this.formData.sixthSection.gridArray.splice(index, 1);
      }
      
    },
    handleFileUploadLoop(event, index, section){
      const file = event.target.files[0]; // Get the uploaded file

      // Set your desired file size limit in bytes (e.g., 1MB = 1024 * 1024 bytes)
      // const fileSizeLimit = 1024 * 1024; // 1MB
      // if (file && file.size > fileSizeLimit) {
      //   this.errorMsg = 'Request Entity Too Large';
      // } else {
        if (file){
          this.previewImageLoop(file, index, section);  // Preview the selected image
        // You can also upload the file to a server using Api or fetch
         this.uploadFileToServerLoop(file, index, section);
        }
        
      // }
      
    },
    previewImageLoop(file, index, section) {
      const reader = new FileReader();
      reader.onload = () => {
        if(section === 'second'){
          this.formData.secondSection.gridArray[index].imageSource = reader.result;
        }
        if(section === 'fourth'){
          this.formData.fourthSection.gridArray[index].textContentOrImage = reader.result;
        }
        if(section === 'sixth'){
          this.formData.sixthSection.gridArray[index].imageSource = reader.result;
        }
          
         // Set the uploaded image as the source
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    },
    uploadFileToServerLoop(file, index, section){
      const formData = new FormData();
      formData.append('file', file);
      API.post('auth/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log('File uploaded!', response);
        if(section === 'second'){
          this.formData.secondSection.gridArray[index].imageSource = response.data.data.location;
        }
        if(section === 'fourth'){
          this.formData.fourthSection.gridArray[index].textContentOrImage = response.data.data.location;
        }
        if(section === 'sixth'){
          this.formData.sixthSection.gridArray[index].imageSource = response.data.data.location;
        }
        
      }).catch(error => {
        console.error('Error uploading file', error);
        this.errorMsg = error.response.data.message;
      });
    },
  },
 }
  </script>
 
  