<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="admin-panel-rgt-hder">
                    <div class="acount-setting">
                        <div class="acount-details dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span><img src="assets/images/Bhatri.png" alt="" /></span>
                        </div>
                        <ul class="dropdown-menu">
                          <!-- <li><a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square"></i></a> Edit</li> -->
                          <li><a class="dropdown-item" href="javascript:void(0);" @click="logout"><i class="fa-solid fa-right-from-bracket"></i> Log out</a></li>
                      </ul>
                    </div>
                </div>
  </template>
  
  <script>
  export default {
    // Your dashboard component logic here
    methods: {
      logout(){
      localStorage.removeItem("adminInfo");
      localStorage.removeItem("adminToken");
      window.location.href = "/";
      }
    }
  }
  </script>
  
  <style>
  /* Your dashboard component styles here */
  </style>
  