import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/Login.vue';
import Dashboard from '@/components/Dashboard.vue';
import Usermanagement from '@/components/Usermanagement.vue';
import Paymentmanagement from '@/components/Paymentmanagement.vue';
import Emailmanagement from '@/components/Emailmanagement.vue';
import Reportmanagement from '@/components/Reportmanagement.vue';
import Trialmanagement from '@/components/Trialmanagement.vue';
import About from '@/components/AboutUs.vue';
import Terms from '@/components/TermsCondition.vue';
import SuggestionInstruction from '@/components/SuggestionInstruction.vue';
import HomePage from '@/components/HomePage.vue';
import OurCommitment from '@/components/OurCommitment.vue';
import ModernLinguistic from '@/components/ModernLinguistic.vue';
import FAQ from '@/components/FAQ.vue';
import ContactUs from '@/components/ContactUs.vue';
import Blogmanagement from '@/components/Blogmanagement.vue';
import PermanentReferral from '@/components/PermanentReferral.vue';

const routes = [
    { path: '/', name: 'Login', component: Login },
    { 
      path: '/dashboard', 
      name:'Dashboard', 
      component: Dashboard,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
    { 
      path: '/usermanagement',
      name:'Usermanagement', 
      component: Usermanagement,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
    { 
      path: '/paymentmanagement', 
      name:'Paymentmanagement', 
      component: Paymentmanagement,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/emailmanagement', 
      name:'Emailmanagement', 
      component: Emailmanagement,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/reportmanagement', 
      name:'Reportmanagement', 
      component: Reportmanagement,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/trialmanagement', 
      name:'Trialmanagement', 
      component: Trialmanagement,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/landing-page', 
      name: 'HomePage', 
      component: HomePage,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/about-us', 
      name: 'AboutUs', 
      component: About,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/terms-condition', 
      name: 'TermsCondition', 
      component: Terms,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/suggestion-instruction', 
      name: 'SuggestionInstruction', 
      component: SuggestionInstruction,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
      { 
      path: '/our-commitment', 
      name: 'OurCommitment', 
      component: OurCommitment,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
      { 
      path: '/modern-linguistic', 
      name: 'ModernLinguistic', 
      component: ModernLinguistic,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
      { 
      path: '/faq', 
      name: 'FAQ', 
      component: FAQ,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/contact-us', 
      name: 'ContactUs', 
      component: ContactUs,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/blogs', 
      name: 'Blogmanagement', 
      component: Blogmanagement,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     },
     { 
      path: '/permanent-referral', 
      name: 'PermanentReferral', 
      component: PermanentReferral,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("adminInfo")) === null ||JSON.parse(localStorage.getItem("adminInfo")) === undefined){
          window.location.href = '/';
        } else {
          next();
        }
      }
     }
  // Other routes...
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;


