<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="main-page admin-panel-main">
        <div class="admin-panel-inner">
            <Layout></Layout>
            <div class="admin-panel-rgt-inner ">
                <Topbar></Topbar>
                <div class="admin-right-cntn">
                    <h3>Trial Management</h3>
                    <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                    <p class="successMsg" v-if="successMsg !== ''">{{ successMsg }}</p>
                    
                    <div class="form-container">
                        <form @submit.prevent="submitForm">
                            <div class="mb-3">
                              <label for="exampleInputEmail1" class="form-label">
                                <strong class="templateHead">Trial Limit (Days)</strong></label>
                              <input type="number" class="form-control" v-model="trialLimit" min="1" id="exampleInputEmail1" aria-describedby="emailHelp">
                            </div>
                            <button type="submit" class="btn btn-primary">Update</button>
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Layout from "@/components/Layout.vue"; 
import Topbar from "@/components/Topbar.vue";
import { API } from "../api/api";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Trialmanagement",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout,
    Topbar
},
data() {
    return {
        returnData: Object,
        trialLimit: Number,
        errorMsg: '',
        successMsg: ''
    };
  },
  created() {
    this.fetchEditorContent();
  },
  methods: {
    async fetchEditorContent() {

      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`trialperiod`, head)
        .then(response => {
          console.log(response, "Trial management details")
          this.returnData = response.data.data;
          this.trialLimit = response.data.data.trialLimit;
        
        })
        .catch(error => {
            console.error('Error fetching Trial management:', error);
        });
    },
    
    submitForm() {
     var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
       
      const sendData = {
        trialId: this.returnData.id,
        trialLimit: this.trialLimit

      }
        // Perform form submission (e.g., API call)
       API.patch("trialperiod/updatetrial",  sendData, head)
     .then(response => {
               console.log(response, "After Template update");
               this.successMsg = response.data.message;
               this.errorMsg = '';
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.successMsg = '';
                 });
     
   },
  },
}
</script>

<style>
/* Your dashboard component styles here */
</style>
