<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="main-page admin-panel-main">
      <div class="admin-panel-inner">
          <Layout></Layout>
          <div class="admin-panel-rgt-inner ">
              <Topbar></Topbar>
              <div class="admin-right-cntn">
                    <div class="dashboard-table-responsive table-responsive">
                      <h3>Blog Management</h3>
                      <button type="button" class="btn btn-success addBtn" data-bs-toggle="modal" data-bs-target="#addBlog">
                        <i class="fa fa-plus" aria-hidden="true"></i> Add Blog
                     </button>
                      <div v-if="successMsg" class="alert">{{ successMsg }}</div>
                      <div class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</div>
                        <table class="table table-bordered bg-light w-100">
                            <thead>
                              <tr>
                                <th scope="col">Cover Photo</th>
                                <th scope="col">Name</th>
                                <th scope="col">Content</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr v-for="(blog, index) in blogList" :key="index">
                                <td><img :src="blog.coverImageUrl" alt="" class="profilePic"/></td>
                                <td>{{ blog.title }}</td>
                                <td v-html="blog.content"></td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" @click="toggleCheckbox(index)" :checked="!blog.isActive">
                                        <div class="slider round"></div>
                                    </label>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" :class="{ 'disabled-link': !blog.isActive }" data-bs-toggle="modal" data-bs-target="#addBlog" @click="getBlogById(index)">
                                        <i class="fa fa-pencil-square" aria-hidden="true" ></i>Edit
                                    </a>
                                </td>
                                <!-- <td>
                                  <a href="#" data-bs-toggle="modal" data-bs-target="#Subscription" class="payment-info" :class="{ 'paymentActive': user.isSubscribed}" @click="getSubscriptionById(user.id)">
                                    {{ user.isSubscribed ? 'Subcribed' : 'Unsubscribed' }}
                                  </a>
                                </td> -->
                              </tr>
                            </tbody>
                          </table>
                        

                          <h6 v-if="blogList.length === 0" class="text-center mt-2">No data found.</h6>
                          <nav aria-label="Page navigation example" v-if="blogList.length !== 0">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Previous"
                                @click="prevPage" :class="{ 'disabled': currentPage === 1 }">
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="javascript:void(0);">{{ currentPage  }}</a></li>
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Next" @click="nextPage" :class="{ 'disabled': isNextDisabled }">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                    </div>
                </div>
          </div>
      </div>

      <!-- Add Blog Modal -->
      <div class="modal fade payment-info-modal" id="addBlog" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">Blog Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                <form @submit.prevent="submitForm" ref="myForm">
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Cover Image</label>
                   <input type="file" class="form-control" id="profilepic" @change="handleFileUpload" accept="image/*" />
                    <div class="previewImage" v-if="formData.coverImageUrl != null">
                        <img :src="formData.coverImageUrl" alt="Uploaded Image"/>
                    </div>
            </div>
            
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Title</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.title">
                <span v-if="formSubmitted && !formData.title">Title is required</span>
            </div>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Content</label>
                <ckeditor :id="`textInput`" :editor="editor" v-model="formData.content" class="custom-ckeditor"></ckeditor>
                <span v-if="formSubmitted && !formData.content">Content is required</span>
            </div>
            
            
            <button type="submit" class="btn" data-bs-dismiss="modal">Submit</button>
            </form>
            
                </div>
            
                </div>
            </div>
           
     </div>
  </section>
</template>

<script>
import Layout from "@/components/Layout.vue"; 
import Topbar from "@/components/Topbar.vue";
import { API } from "../api/api";
// Import CKEditor component and package
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Blogmanagement",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout,
    Topbar,
    ckeditor: CKEditor.component
},
data() {
    return {
      editor: ClassicEditor,
      blogList: [],
      currentPage: 1,
      limit: 10, // Adjust the limit as needed
      totalBlogCount:null,
      successMsg: '',
      formData: {
        coverImageUrl: null,
        title: '',
        content: '',
      },
      formSubmitted: false, // Track form submission status
      errorMsg: '',
      checkbox: false,
      isUpdateData: false,
      uniqueGetBlogId: Number
    };
  },
  created() {
    this.fetchBlogs();
  },
  methods: {
    fetchBlogs() {
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`blog/all?page=${this.currentPage}&limit=${this.limit}`, head)
        .then(response => {
          console.log(response, "All Blog details")
          this.blogList = response.data.data.rows; // Assuming the response contains an array of users
          this.totalBlogCount = response.data.data.count;
        })
        .catch(error => {
          console.error('Error fetching users', error);
        });
    },
    nextPage() {
      this.currentPage++;
      this.fetchBlogs();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchBlogs();
      }
    },

    handleFileUpload(event){
        const file = event.target.files[0]; // Get the uploaded file
      if (file) {
         this.previewImage(file);  // Preview the selected image
        // You can also upload the file to a server using Api or fetch
         this.uploadFileToServer(file);
      }
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
          this.formData.coverImageUrl = reader.result;
         // Set the uploaded image as the source
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    },
    uploadFileToServer(file) {
      const formData = new FormData();
      formData.append('file', file);
      API.post('auth/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log('File uploaded!', response);
          this.formData.coverImageUrl = response.data.data.location;
      }).catch(error => {
        console.error('Error uploading file', error);
      });
    },

    addBlog(){
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
        API.post("blog/add",  this.formData, head)
     .then(response => {
               console.log(response, "AfterBlog Added");
               this.successMsg = response.data.message;
               this.isUpdateData = false;
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
               this.fetchBlogs();
               this.closeModal();
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.isUpdateData = false;
                 });
    },
    updateBlog(){

        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
          this.formData = { ...this.formData, id: this.uniqueGetBlogId };
          API.patch("blog/update",  this.formData, head)
     .then(response => {
               console.log(response, "After Blog Updated");
               this.successMsg = response.data.message;
               this.isUpdateData = false;
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
               this.fetchBlogs();
               this.closeModal();
               this.uniqueGetBlogId = null;
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.isUpdateData = false;
                 this.uniqueGetBlogId = null;
                 });

    },

    submitForm() {
     this.formSubmitted = true; // Set form submitted status
     if (this.validateForm()) {
          if(this.isUpdateData)this.updateBlog()
          else this.addBlog()

          } else {
            console.log('Form has errors');
            this.errorMsg = "Form has errors";
          }
     
   },
   validateForm() {
      return this.formData.title &&
             this.formData.content
    },
    closeModal(){
        this.$refs.myForm.reset();
        this.formSubmitted = false;
        this.formData = {
        coverImageUrl: null,
        title: '',
        content: '',
      }
    },
    toggleCheckbox(index) {
        this.blogList[index].isActive = !this.blogList[index].isActive
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
          const sendData = {
            id: this.blogList[index].id ,
            status: this.blogList[index].isActive  
          }
          API.patch("blog/activateordeactivate",  sendData, head)
     .then(response => {
               console.log(response, "After Status Change");
               this.successMsg = response.data.message;
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 });
      
    //   this.$emit('setCheckboxVal', this.checkbox)
    },
    getBlogById(index){
        console.log(this.blogList[index]);
        this.uniqueGetBlogId = this.blogList[index].id; 
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
        API.get(`blog/details/?blogId=${this.blogList[index].blogUniqueId}`, head)
     .then(response => {
               console.log(response, "Blog details by ID");
               this.formData = response.data.data;
               this.isUpdateData = true;
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.isUpdateData = false;
                 });
      
    }
    
  },
  computed: {
    isNextDisabled() {
      return this.currentPage * this.limit >= this.totalBlogCount;
    }
  },
}
</script>

<style scoped>
</style>
