<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="admin-panel-inner-lft">
      <div class="admin-panel-inner-lft-content">
                <div class="admin-panel-hder-logo">
                  <router-link to="/dashboard">
                  <a href="javascript:void(0)"><img src="assets/images/aanantya-logo.png" alt=""></a>
                </router-link>
                </div>
                <div class="admin-panel-lft-tab">
                    <ul class="list-inline">
                      <li :class="{ 'active': $route.path === '/dashboard' }">
                          <router-link to="/dashboard">
                            <a href="javascript:void(0);"><i class="fa-solid fa-gauge"></i> Dashboard</a>
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/usermanagement' }">
                          <router-link to="/usermanagement">
                            <a href="javascript:void(0);"><i class="fa-solid fa-users"></i> User Management</a>
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/paymentmanagement' }">
                          <router-link to="/paymentmanagement">
                            <a href="javascript:void(0);"><i class="fa-solid fa-hand-holding-heart"></i> Package Management</a>
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/emailmanagement' }">
                          <router-link to="/emailmanagement">
                            <a href="javascript:void(0);"><i class="fa-solid fa-envelope"></i> Email Management</a>
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/reportmanagement' }">
                          <router-link to="/reportmanagement">
                            <a href="javascript:void(0);"><i class="fa-solid fa-file-invoice"></i>Payment Report Management</a>
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/trialmanagement' }">
                          <router-link to="/trialmanagement">
                            <a href="javascript:void(0);"><i class="fa-solid fa-calendar-days"></i>Trial Day Management</a>
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/landing-page' }">
                          <router-link to="/landing-page">
                            <i class="fa fa-home" aria-hidden="true"></i>Landing Page
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/about-us' ||
                         $route.path === '/terms-condition' || $route.path === '/suggestion-instruction'}">
                            <a href="javascript:void(0);" class="dropdown-toggle"
                             :class="{ 'show': $route.path === '/about-us' 
                             || $route.path === '/terms-condition' 
                             || $route.path === '/suggestion-instruction'
                             || $route.path === '/our-commitment'
                             || $route.path === '/modern-linguistic'
                             || $route.path === '/faq'
                             || $route.path === '/contact-us'
                             }"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fa-solid fa-pen"></i>Content Management</a>
                              <ul class="dropdown-menu contentPageList" 
                              :class="{ 'show': $route.path === '/about-us' 
                              || $route.path === '/terms-condition' 
                              || $route.path === '/suggestion-instruction'
                              || $route.path === '/our-commitment'
                              || $route.path === '/modern-linguistic'
                              || $route.path === '/faq'
                              || $route.path === '/contact-us'
                              }">
                                <li :class="{ 'active': $route.path === '/about-us' }">
                                  <router-link to="/about-us">
                                    <a class="dropdown-item" href="javascript:void(0);">
                                      <i class="fa-solid fa-address-card"></i>About Us</a>
                                  </router-link>
                                </li>
                                <li :class="{ 'active': $route.path === '/terms-condition' }">
                                  <router-link to="/terms-condition">
                                    <a class="dropdown-item" href="javascript:void(0);">
                                      <i class="fa-solid fa-file-invoice"></i>Terms & Condition & Privacy Policy</a>
                                  </router-link>
                                </li>
                                <li :class="{ 'active': $route.path === '/suggestion-instruction' }">
                                  <router-link to="/suggestion-instruction">
                                    <a class="dropdown-item" href="javascript:void(0);">
                                      <i class="fa-regular fa-pen-to-square"></i>Suggestion & Instructions</a>
                                  </router-link>
                                </li>
                                <li :class="{ 'active': $route.path === '/our-commitment' }">
                                  <router-link to="/our-commitment">
                                    <a class="dropdown-item" href="javascript:void(0);">
                                      <i class="fa-regular fa-comment"></i>Our Commitment</a>
                                  </router-link>
                                </li>
                                <li :class="{ 'active': $route.path === '/modern-linguistic' }">
                                  <router-link to="/modern-linguistic">
                                    <a class="dropdown-item" href="javascript:void(0);">
                                      <i class="fa-solid fa-lightbulb"></i>Modern Linguistic Techniques</a>
                                  </router-link>
                                </li>
                                <li :class="{ 'active': $route.path === '/faq' }">
                                  <router-link to="/faq">
                                    <a class="dropdown-item" href="javascript:void(0);">
                                      <i class="fa-solid fa-circle-question"></i>FAQ</a>
                                  </router-link>
                                </li>
                                <li :class="{ 'active': $route.path === '/contact-us' }">
                                  <router-link to="/contact-us">
                                    <a class="dropdown-item" href="javascript:void(0);">
                                      <i class="fa-solid fa-phone"></i>Contact Us</a>
                                  </router-link>
                                </li>
                              </ul>
                        </li>
                        <li :class="{ 'active': $route.path === '/blogs' }">
                          <router-link to="/blogs">
                            <i class="fa fa-rss-square" aria-hidden="true"></i>Blog Management
                          </router-link>
                        </li>
                        <li :class="{ 'active': $route.path === '/permanent-referral' }">
                          <router-link to="/permanent-referral">
                            <i class="fa fa-share-square" aria-hidden="true"></i> Permanent Referral Code
                          </router-link>
                        </li>
                       
                       
                    </ul>
                </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  
  <style>
  /* Your dashboard component styles here */
  </style>
  