<!-- eslint-disable vue/multi-word-component-names -->
<template>
     <section class="main-page admin-panel-main">
      <div class="admin-panel-inner">
          <Layout></Layout>
          <div class="admin-panel-rgt-inner ">
              <Topbar></Topbar>
              <div class="admin-right-cntn">
                    <div class="dashboard-table-responsive table-responsive">
                      <h3>FAQ Management</h3>
                      <button type="button" class="btn btn-success addBtn" data-bs-toggle="modal" data-bs-target="#addFaq">
                        <i class="fa fa-plus" aria-hidden="true"></i> Add FAQ
                     </button>
                      <div v-if="successMsg" class="alert">{{ successMsg }}</div>
                      <div class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</div>
                        <table class="table table-bordered bg-light w-100">
                            <thead>
                              <tr>
                                <th scope="col">Question</th>
                                <th scope="col">Answer</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr v-for="(faq, index) in faqList" :key="index">
                                <td>{{ faq.title }}</td>
                                <td>{{ faq.content }}</td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" @click="toggleCheckbox(index)" :checked="!faq.isActive">
                                        <div class="slider round"></div>
                                    </label>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" :class="{ 'disabled-link': !faq.isActive }" data-bs-toggle="modal" data-bs-target="#addFaq" @click="getFaqById(index)">
                                        <i class="fa fa-pencil-square" aria-hidden="true" ></i>Edit
                                    </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        

                          <h6 v-if="faqList.length === 0" class="text-center mt-2">No data found.</h6>
                          <nav aria-label="Page navigation example" v-if="faqList.length !== 0">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Previous"
                                @click="prevPage" :class="{ 'disabled': currentPage === 1 }">
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="javascript:void(0);">{{ currentPage  }}</a></li>
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Next" @click="nextPage" :class="{ 'disabled': isNextDisabled }">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                    </div>
                </div>
          </div>
      </div>

      <!-- Add Blog Modal -->
      <div class="modal fade payment-info-modal" id="addFaq" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">FAQ Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                <form @submit.prevent="submitForm" ref="myForm">
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Question</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.title">
                <span v-if="formSubmitted && !formData.title">Title is required</span>
            </div>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Answer</label>
                <textarea class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.content" style="height: 150px;"></textarea>
                <span v-if="formSubmitted && !formData.content">Content is required</span>
            </div>
            
            
            <button type="submit" class="btn" data-bs-dismiss="modal">Submit</button>
            </form>
            
                </div>
            
                </div>
            </div>
           
     </div>
  </section>
  </template>
  
  <script>
  import Layout from "@/components/Layout.vue"; 
  import Topbar from "@/components/Topbar.vue";
  import { API } from "../api/api";
  
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "FAQ",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      Layout,
      Topbar
  },
  data() {
    return {
      faqList: [],
      currentPage: 1,
      limit: 10, // Adjust the limit as needed
      totalBlogCount:null,
      successMsg: '',
      formData: {
        title: '',
        content: '',
      },
      formSubmitted: false, // Track form submission status
      errorMsg: '',
      checkbox: false,
      isUpdateData: false,
      uniqueGetFaqId: Number
    };
  },
  created() {
    this.fetchFaqs();
  },
  methods: {
    fetchFaqs() {
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`faq/all?page=${this.currentPage}&limit=${this.limit}`, head)
        .then(response => {
          console.log(response, "All FAQ details")
          this.faqList = response.data.data.rows; // Assuming the response contains an array of users
          this.totalFaqCount = response.data.data.count;
        })
        .catch(error => {
          console.error('Error fetching users', error);
        });
    },
    nextPage() {
      this.currentPage++;
      this.fetchFaqs();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchFaqs();
      }
    },

    addFaq(){
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
        API.post("faq/add",  this.formData, head)
     .then(response => {
               console.log(response, "AfterBlog Added");
               this.successMsg = response.data.message;
               this.isUpdateData = false;
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
               this.fetchFaqs();
               this.closeModal();
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.isUpdateData = false;
                 });
    },
    updateFaq(){
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
          this.formData = { ...this.formData, id: this.uniqueGetFaqId };
          API.patch("faq/update",  this.formData, head)
     .then(response => {
               console.log(response, "After FAQ Updated");
               this.successMsg = response.data.message;
               this.isUpdateData = false;
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
               this.fetchFaqs();
               this.closeModal();
               this.uniqueGetFaqId = null;
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.isUpdateData = false;
                 this.uniqueGetFaqId = null;
                 });

    },

    submitForm() {
     this.formSubmitted = true; // Set form submitted status
     if (this.validateForm()) {
          if(this.isUpdateData)this.updateFaq()
          else this.addFaq()

          } else {
            console.log('Form has errors');
            this.errorMsg = "Form has errors";
          }
     
   },
   validateForm() {
      return this.formData.title &&
             this.formData.content
    },
    closeModal(){
        this.$refs.myForm.reset();
        this.formSubmitted = false;
        this.formData = {
        coverImageUrl: null,
        title: '',
        content: '',
      }
    },
    toggleCheckbox(index) {
        this.faqList[index].isActive = !this.faqList[index].isActive
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
          const sendData = {
            id: this.faqList[index].id ,
            status: this.faqList[index].isActive  
          }
          API.patch("faq/activateordeactivate",  sendData, head)
     .then(response => {
               console.log(response, "After Status Change");
               this.successMsg = response.data.message;
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 });
      
    //   this.$emit('setCheckboxVal', this.checkbox)
    },
    getFaqById(index){
        console.log(this.faqList[index]);
        this.uniqueGetFaqId = this.faqList[index].id; 
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
        API.get(`faq/details/?faqId=${this.faqList[index].faqUniqueId}`, head)
     .then(response => {
               console.log(response, "Faq details by ID");
               this.formData = response.data.data;
               this.isUpdateData = true;
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.isUpdateData = false;
                 });
      
    }
    
  },
  computed: {
    isNextDisabled() {
      return this.currentPage * this.limit >= this.totalFaqCount;
    }
  },
}
</script>