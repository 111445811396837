<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="main-page admin-panel-main">
        <div class="admin-panel-inner">
            <Layout></Layout>
            <div class="admin-panel-rgt-inner ">
                <Topbar></Topbar>
                <div class="admin-right-cntn">
                    <h3>About Us</h3>
                    <!-- <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p> -->
                    <div v-if="errorMsg" class="alert-error">{{ errorMsg }}</div>
                    <!-- <p class="successMsg" v-if="successMsg !== ''">{{ successMsg }}</p> -->
                    <div v-if="successMsg" class="alert">{{ successMsg }}</div>
                    <div class="form-container">
                        <form @submit.prevent="submitForm">
                            <div class="mb-3">
                              <label for="exampleInputEmail1" class="form-label"><strong class="templateHead">Content: </strong></label>
                              <ckeditor :editor="editor" v-model="aboutContent"></ckeditor>
                            </div>
                            <button type="submit" class="btn btn-primary">Submit</button>
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Layout from "@/components/Layout.vue"; 
import Topbar from "@/components/Topbar.vue";
import { API } from "../api/api";
// Import CKEditor component and package
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AboutUs",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout,
    Topbar,
    ckeditor: CKEditor.component
},
data() {
    return {
        editor: ClassicEditor,
        aboutContent: '',
        errorMsg: '',
        successMsg: '',
    };
  },
  created() {
    this.fetchEditorContent();
  },
  methods: {
    async fetchEditorContent() {

      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`generic/about`, head)
        .then(response => {
          console.log(response, "About Us details details")
          this.aboutContent = response.data.data || [];
          console.log(this.aboutContent, "this.aboutContent")
          
        })
        .catch(error => {
            console.error('Error fetching editor content:', error);
        });
    },
    
    submitForm() {
     var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      
      const sendData = {
        cmsContent: this.aboutContent
      }
        // Perform form submission (e.g., API call)
       API.patch("generic/about",  sendData, head)
     .then(response => {
               console.log(response, "After Template update");
               this.successMsg = response.data.message;
               this.errorMsg = '';
           }).catch(error => {
                 console.error("fail", error);
                 this.successMsg = '';
                 this.errorMsg = error.response.data.message;
                 });
     
   },
  },
}
</script>

<style>
/* Your dashboard component styles here */
</style>
