<template>
  <section class="main-page admin-panel-main">
      <div class="admin-panel-inner">
          <Layout></Layout>
          <div class="admin-panel-rgt-inner ">
              <Topbar></Topbar>
              <div class="admin-right-cntn">
                    <div class="dashboard-table-responsive table-responsive">
                      <h3>Pakage Management</h3>
                        <table class="table table-bordered bg-light w-100">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Amount (USD)</th>
                                <th scope="col">Amount (INR)</th>
                                <th scope="col">Description</th>
                                <th scope="col">Token Limit</th>
                                <!-- <th scope="col">Selected Country</th> -->
                                <th scope="col">Create Date</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr v-for="(pakage, index) in pakageList" :key="index">
                                <td>{{ pakage.packageName }}</td>
                                <td>$ {{ pakage.price }}</td>
                                <td>₹ {{ pakage.priceInr }}</td>
                                <td v-html="pakage.description"></td>
                                <td>{{ pakage.tokenLimit }}</td>
                                <!-- <td><span v-for="(country, index) in pakage.allowedCountries" :key="index">
                                  {{ country.countryName }},&nbsp;
                                </span></td> -->
                                <td>{{ formatDateTime(pakage.createdAt) }}</td>
                                <td>
                                  <a href="#" data-bs-toggle="modal" data-bs-target="#Subscription" class="payment-info" @click="getPakageInfoById(pakage)">
                                    <i class="fa-regular fa-pen-to-square"></i> Update
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        <div class="modal fade payment-info-modal" :class="{ 'show': isPakageModalVisible }" id="Subscription" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-lg">
                          <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Payment Information</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                            <form @submit.prevent="submitForm">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Pakage Name</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.name">
                          <span v-if="formSubmitted && !formData.name">Pakage Name is required</span>
                        </div>
                        
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Amount (USD)</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.amount">
                          <span v-if="formSubmitted && !formData.amount">Amount is required</span>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Amount (INR)</label>
                          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.priceInr">
                          <span v-if="formSubmitted && !formData.priceInr">Amount is required</span>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Token Limit</label>
                          <input type="number" class="form-control" min="1" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.tokenLimit">
                          <span v-if="formSubmitted && !formData.tokenLimit">Token is required</span>
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Description</label>
                          <!-- <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.description"> -->
                          <ckeditor :editor="editor" v-model="formData.description" class="form-control"></ckeditor>
                          <span v-if="formSubmitted && !formData.description">Description is required</span>
                        </div>
                        <!-- <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Selected Country</label>
                          <div class="select-boxes">
                          <div v-for="(country, index) in countryList" :key="index">
                            <input type="checkbox" :value="country.id" v-model="formData.selectedcountry"> {{ country.countryName }}
                          </div>
                          </div>
                          <span v-if="formSubmitted && formData.selectedcountry.length === 0">
                            Please select at least one option in the select box.
                          </span>
                        </div> -->
                        <button type="submit" class="btn">Submit</button>
                      </form>
                     
                          </div>
                        
                          </div>
                          </div>
                        </div>

                          <!-- <h6 v-if="pakageList.length === 0" class="text-center mt-2">No data found.</h6> -->
                          <!-- <nav aria-label="Page navigation example" v-if="pakageList.length !== 0">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Previous"
                                @click="prevPage" :class="{ 'disabled': currentPage === 1 }">
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="javascript:void(0);">{{ currentPage  }}</a></li>
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Next" @click="nextPage" :class="{ 'disabled': isNextDisabled }">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                          </nav> -->
                    </div>
                </div>
          </div>
      </div>
  </section>
</template>

<script>
import Layout from "@/components/Layout.vue"; 
import Topbar from "@/components/Topbar.vue";
import { API } from "../api/api";
// Import CKEditor component and package
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Paymentmanagement",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout,
    Topbar,
    ckeditor: CKEditor.component
},
data() {
    return {
      editor: ClassicEditor,
      pakageList: [],
      currentPage: 1,
      limit: 10, // Adjust the limit as needed
      totalpakageCount:null,
      formData: {
        name: '',
        description: '',
        amount: '',
        priceInr: '',
        packageSlug: '',
        tokenLimit: Number
       // selectedcountry: []
      },
      formSubmitted: false, // Track form submission status
      errorMsg: '',
      isPakageModalVisible: '',
      countryList : []
    };
  },
  created() {
    this.fetchPakages();
    this.getCountryList();
  },
  methods: {
    getCountryList(){
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`countries/list`, head)
        .then(response => {
          console.log(response, "All country details")
          this.countryList = response.data.data; // Assuming the response contains an array of users
        })
        .catch(error => {
          console.error('Error fetching country', error);
        });
    },
    fetchPakages() {
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`packages/list`, head)
        .then(response => {
          console.log(response, "All pakage details")
          this.pakageList = response.data.data; // Assuming the response contains an array of users
          // this.totalUserCount = response.data.data.count;
          console.log(this.pakageList, "this.pakageList")
        })
        .catch(error => {
          console.error('Error fetching pakages', error);
        });
    },
    nextPage() {
      this.currentPage++;
      this.fetchPakages();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchPakages();
      }
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      // const hours = `0${date.getHours()}`.slice(-2);
      // const minutes = `0${date.getMinutes()}`.slice(-2);
      
      // return `${year}-${month}-${day} ${hours}:${minutes}`;
      return `${year}-${month}-${day}`;
    },
    getPakageInfoById(pkgInfo){
      //this.formData.selectedcountry = [];
       this.isPakageModalVisible = true;
       this.formData.name = pkgInfo.packageName;
       this.formData.description = pkgInfo.description;
       this.formData.amount = `${pkgInfo.price}`;
       this.formData.priceInr = `${pkgInfo.priceInr}`;
       this.formData.packageSlug = pkgInfo.packageSlug;
       this.formData.tokenLimit = pkgInfo.tokenLimit
      // pkgInfo.allowedCountries.map(item => this.formData.selectedcountry.push(item.id));
       console.log(this.formData);
       this.errorMsg = '';
    },
    submitForm() {
     this.formSubmitted = true; // Set form submitted status
     if (this.validateForm()) {
     var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
       const sendData = {
        packageName: this.formData.name,
        description: this.formData.description,
        price: this.formData.amount,
        priceInr: this.formData.priceInr,
        packageSlug: this.formData.packageSlug,
        tokenLimit: this.formData.tokenLimit

       // selectedCountry: this.formData.selectedcountry
       }
        // Perform form submission (e.g., API call)
       API.patch("packages/update",  sendData, head)
     .then(response => {
               console.log(response, "After pakage update");
               this.isPakageModalVisible = false;
               const backdropElement = document.querySelector('.modal-backdrop');
                  if (backdropElement) {
                    backdropElement.remove();
                  }
               this.fetchPakages();
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                //  this.isPakageModalVisible = false;
                 });

          } else {
            console.log('Form has errors');
            this.errorMsg = "Form has errors";
          }
     
   },
   validateForm() {
      return this.formData.name &&
             this.formData.description &&
             this.formData.amount &&
             this.formData.priceInr &&
             this.formData.tokenLimit
    },
   
  },
}
</script>

<style>
/* Your dashboard component styles here */
</style>
