<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="main-page admin-panel-main">
      <div class="admin-panel-inner">
          <Layout></Layout>
          <div class="admin-panel-rgt-inner ">
              <Topbar></Topbar>
              <div class="admin-right-cntn">
                    <div class="dashboard-table-responsive table-responsive">
                      <h3>User Management</h3>
                      <button type="button" class="btn btn-success addBtn" @click="generatePassword">
                        <i class="fa fa-plus" aria-hidden="true"></i> Add User
                     </button>
                      <div v-if="successMsg" class="alert">{{ successMsg }}</div>
                        <table class="table table-bordered bg-light w-100">
                            <thead>
                              <tr>
                                <th scope="col">Photo</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Create Date</th>
                                <th scope="col">Payment Status</th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr v-for="(user, index) in userList" :key="index">
                                <td><img :src="user.profilePicture" alt="" class="profilePic"/></td>
                                <td>{{ user.firstName }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ formatDateTime(user.createdAt) }}</td>
                                <td>
                                  <a href="#" data-bs-toggle="modal" data-bs-target="#Subscription" class="payment-info" :class="{ 'paymentActive': user.isSubscribed}" @click="getSubscriptionById(user.id)">
                                    {{ user.isSubscribed ? 'Subcribed' : 'Unsubscribed' }}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        <div class="modal fade payment-info-modal" id="Subscription" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
                          <div class="modal-dialog">
                          <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Payment Information</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" @click="closeModal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                          <ul v-for="(info, index) in subscriptionInfo" :key="index">
                            <li><strong>Pakage Name</strong>: {{ info.package.packageName }}</li>
                            <li><strong>Pakage Amount</strong>: {{ info.package.price }}$</li>
                            <li><strong>Subscription Date</strong>: {{ formatDateTime(info.current_period_start) }}</li>
                            <li><strong>Expired Date</strong>: {{ formatDateTime(info.current_period_end) }}</li>
                            <li>
                              <button v-if="info.isActive && !info.isCancelationRequested" type="button" class="btn" @click="reqestCancelSubscription" data-bs-dismiss="modal">Cancel</button>
                              <button v-else-if="info.isActive && info.isCancelationRequested" type="button" class="btn" disabled>Cancelation Initiated...</button>
                            </li>
                          </ul>
                          <h3 class="nosubscriptionInfo" v-if="subscriptionInfo.length === 0"> Payment information not available</h3>
                          </div>
                        
                          </div>
                          </div>
                        </div>

                          <h6 v-if="userList.length === 0" class="text-center mt-2">No data found.</h6>
                          <nav aria-label="Page navigation example" v-if="userList.length !== 0">
                            <ul class="pagination">
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Previous"
                                @click="prevPage" :class="{ 'disabled': currentPage === 1 }">
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              <li class="page-item"><a class="page-link" href="javascript:void(0);">{{ currentPage  }}</a></li>
                              <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                              <li class="page-item">
                                <a class="page-link" href="javascript:void(0);" aria-label="Next" @click="nextPage" :class="{ 'disabled': isNextDisabled }">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                    </div>
                </div>
          </div>
      </div>

       <!-- Add User Modal -->
       <div class="modal fade payment-info-modal" id="addUser" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">

<div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title">Create User</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeUserModal"></button>
        </div>
        <div class="modal-body">
          <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
        <form @submit.prevent="submitForm" ref="myForm">
    
    <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Full Name</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.first_name">
        <span v-if="formSubmitted && !formData.first_name">Full Name is required</span>
    </div>
    <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Email</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.user_email">
        <span v-if="formSubmitted && !formData.user_email">Email is required</span>
    </div>

    <!-- <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Password</label>
        <input type="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="formData.user_password" disabled>
    </div> -->
    
    
    <button type="submit" class="btn">Submit</button>
    </form>
    
        </div>
    
        </div>
    </div>
   
</div>
  </section>
</template>

<script>
import Layout from "@/components/Layout.vue"; 
import Topbar from "@/components/Topbar.vue";
import { API } from "../api/api";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Usermanagement",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout,
    Topbar
},
data() {
    return {
      userList: [],
      currentPage: 1,
      limit: 10, // Adjust the limit as needed
      totalUserCount:null,
      subscriptionInfo: [],
      userInfo: Object,
      successMsg: '',
      formData: {
        first_name: '',
        user_email: '',
        // user_password: null
      },
      formSubmitted: false, // Track form submission status
      errorMsg: ''
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`user/all?page=${this.currentPage}&limit=${this.limit}`, head)
        .then(response => {
          console.log(response, "All user details")
          this.userList = response.data.data.rows; // Assuming the response contains an array of users
          this.totalUserCount = response.data.data.count;
        })
        .catch(error => {
          console.error('Error fetching users', error);
        });
    },
    nextPage() {
      this.currentPage++;
      this.fetchUsers();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchUsers();
      }
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      // const hours = `0${date.getHours()}`.slice(-2);
      // const minutes = `0${date.getMinutes()}`.slice(-2);
      
      // return `${year}-${month}-${day} ${hours}:${minutes}`;
      return `${year}-${month}-${day}`;
    },
    getSubscriptionById(userId){
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
      API.get(`subscription/list/${userId}`, head)
        .then(response => {
          console.log(response, "User subscription details by ID");
          this.userInfo = response.data.data;
          this.subscriptionInfo = response.data.data.subscriptions;
        })
        .catch(error => {
          console.error('Error fetching subscription details by ID', error);
        });

    },
    closeModal(){
      this.subscriptionInfo = [];
      this.userInfo = {};
    },
    reqestCancelSubscription(){
      var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
          const sendData = {
            userId : this.userInfo.userId
          }
          console.log(sendData, "sendData===")
      API.post(`subscription/cancelbyadmin`, sendData, head)
        .then(response => {
          console.log(response, "request for cancel subscription")
          this.closeModal();
          this.successMsg = response.data.message;
              setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
            })
        .catch(error => {
          console.error('Error cancel subscription', error);
        });
    },
    generatePassword() {
      this.errorMsg = '';
      // Show the modal using document.querySelector
      const modal = document.querySelector('#addUser');
      if(modal){
        modal.classList.add('show');
      modal.style.display = 'block'; // Ensure the modal is displayed
      }
      
     // const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      //const specialCharacters = '!@#$%^&*()-_=+[{]};:,<.>/?';
      //const alphanumeric = 'abcdefghijklmnopqrstuvwxyz0123456789';

     // let password = '';
      
      // Ensure at least one uppercase letter
     // password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];

      // Ensure at least one special character
      //password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

      // Fill the remaining characters with alphanumeric characters
      //for (let i = 0; i < 8; i++) {
       // const randomIndex = Math.floor(Math.random() * alphanumeric.length);
       // password += alphanumeric[randomIndex];
     // }

      // Shuffle the password to randomize the position of uppercase and special characters
      //password = password.split('').sort(() => Math.random() - 0.5).join('');
     // this.formData.user_password = password;
    },
    submitForm() {
     this.formSubmitted = true; // Set form submitted status
     if (this.validateForm()) {
          this.addUser()
          } else {
            console.log('Form has errors');
            this.errorMsg = "Form has errors";
          }
     
   },
   validateForm() {
      return this.formData.first_name &&
             this.formData.user_email
    },
    addUser(){
        var head = {
            headers: {
                "accesstoken": JSON.parse(localStorage.getItem("adminToken"))
            }
          };
        API.post("auth/createUser",  this.formData, head)
     .then(response => {
               console.log(response, "AfterUser Added");
               this.successMsg = response.data.message;
               this.errorMsg = '';
               setTimeout(() => {
            this.successMsg = '';
          }, 3000); // Hide alert after 3 seconds
               this.fetchUsers();
              this.closeUserModal();
               this.formSubmitted = false;
           }).catch(error => {
                 console.error("fail", error);
                  this.errorMsg = error.response.data.message;
                 this.formSubmitted = false;
                 });
    },
    closeUserModal(){
        this.$refs.myForm.reset();
        this.formSubmitted = false;
        this.formData = {
          first_name: '',
          user_email: '',
          // user_password: null
      }
       // Hide the modal using document.querySelector
       const modal = document.querySelector('#addUser');
       if(modal){
        modal.classList.remove('show');
        modal.style.display = 'none'; // Hide the modal
       }
       const backdropElement = document.querySelector('.modal-backdrop');
        if (backdropElement) {
          backdropElement.remove();
        }
      
    },
  },
  computed: {
    isNextDisabled() {
      return this.currentPage * this.limit >= this.totalUserCount;
    }
  },
}
</script>

<style>
/* Your dashboard component styles here */
</style>
